import {
    IGetByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    onUnauthorized,
} from 'entities/auth/functions';

import {
    INSTANCE_PATH,
} from './constants';

export async function getByUser(): Promise<IGetByUser> {
    return new Fetch<IGetByUser>({
        url: `${config.URL}/${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([500], (body) => {
            return body;
        })
        .exec();
}
