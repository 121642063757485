export const INSTANCE_PATH = 'payment_methods';

export enum RESPONSE_ERROR {
    DUPLICATED_ID = 'DUPLICATED_ID',
    DUPLICATED_NAME = 'DUPLICATED_NAME',
}

export enum PAYMENT_METHOD_TYPE {
    PAY_IN = 'PAY_IN',
    PAY_OUT = 'PAY_OUT',
}

export enum PAYMENT_METHOD_MODE {
    MANUAL = 'MANUAL',
    BKASH = 'BKASH',
    UPI = 'UPI',
    NAGAD = 'NAGAD',
    ASU = 'ASU',
    UPI_QR_INTENT_ELD = 'UPI_QR_INTENT_ELD',
}

export enum CLIENT_FIELD_TYPE {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    REGEX = 'REGEX',
    DATETIME = 'DATETIME',
}
