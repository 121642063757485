import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import {
    InfiniteScroll,
} from 'components/other';

const RowsWrapper: FC<IProps> = (props) => {
    if (!props.infiniteScroll) {
        return props.children;
    }

    return (
        <InfiniteScroll
            hasMoreBottom={props.infiniteScroll.hasMoreBottom}
            loadMoreBottom={props.infiniteScroll.loadMoreBottom}
            withWindowScroll={true}
        >
            {props.children}
        </InfiniteScroll>
    );
};

export default RowsWrapper;
