export const INSTANCE_PATH = 'users';

export enum RESPONSE_ERROR {
    DUPLICATED_USERNAME = 'DUPLICATED_USERNAME',
}

export enum ROLE {
    SUPER_ADMIN = 'SUPER_ADMIN',
    USER = 'USER',
}

export enum PERMISSION {
    PAYMENTS_VIEW = 'PAYMENTS_VIEW',
    PAYMENTS_CREATE_PAY_IN = 'PAYMENTS_CREATE_PAY_IN',
    PAYMENTS_CREATE_PAY_OUT = 'PAYMENTS_CREATE_PAY_OUT',
    PAYMENTS_EDIT_PAY_IN_STATUS = 'PAYMENTS_EDIT_PAY_IN_STATUS',
    PAYMENTS_EDIT_PAY_OUT_STATUS = 'PAYMENTS_EDIT_PAY_OUT_STATUS',
    PAYMENTS_EDIT_PAY_IN = 'PAYMENTS_EDIT_PAY_IN',
    PAYMENTS_EDIT_PAY_OUT = 'PAYMENTS_EDIT_PAY_OUT',
    PAYMENTS_CHANGE_TERMINATE_STATUS = 'PAYMENTS_CHANGE_TERMINATE_STATUS',

    ACCOUNTS_VIEW = 'ACCOUNTS_VIEW',
    ACCOUNTS_CREATE_PAY_IN = 'ACCOUNTS_CREATE_PAY_IN',
    ACCOUNTS_CREATE_PAY_OUT = 'ACCOUNTS_CREATE_PAY_OUT',
    ACCOUNTS_EDIT = 'ACCOUNTS_EDIT',
    ACCOUNTS_ACTIVATE = 'ACCOUNTS_ACTIVATE',
    ACCOUNTS_INACTIVATE = 'ACCOUNTS_INACTIVATE',
    ACCOUNTS_DELETE = 'ACCOUNTS_DELETE',

    PAYMENT_METHODS_VIEW = 'PAYMENT_METHODS_VIEW',
    PAYMENT_METHODS_CREATE = 'PAYMENT_METHODS_CREATE',
    PAYMENT_METHODS_EDIT = 'PAYMENT_METHODS_EDIT',

    MERCHANTS_VIEW = 'MERCHANTS_VIEW',
    MERCHANTS_EDIT = 'MERCHANTS_EDIT',

    SETTLEMENTS_VIEW = 'SETTLEMENTS_VIEW',
    SETTLEMENTS_CREATE = 'SETTLEMENTS_CREATE',

    AGENTS_VIEW = 'AGENTS_VIEW',
    AGENTS_EDIT = 'AGENTS_EDIT',

    PREPAYS_CREATE = 'PREPAYS_CREATE',

    POST_BACK_HISTORIES_VIEW = 'POST_BACK_HISTORIES_VIEW',
    POST_BACK_HISTORIES_RESEND = 'POST_BACK_HISTORIES_RESEND',

    COMPLAINTS_VIEW = 'COMPLAINTS_VIEW',
    COMPLAINTS_ALL = 'COMPLAINTS_ALL',
    COMPLAINTS_CREATE = 'COMPLAINTS_CREATE',
    COMPLAINTS_EDIT = 'COMPLAINTS_EDIT',
    COMPLAINTS_CHANGE_STATUS = 'COMPLAINTS_CHANGE_STATUS',
    COMPLAINTS_CHANGE_CHECKED_STATUS = 'COMPLAINTS_CHANGE_CHECKED_STATUS',
    COMPLAINTS_CHANGE_TERMINATE_STATUS = 'COMPLAINTS_CHANGE_TERMINATE_STATUS',

    COMPLAINT_POST_BACKS_HISTORIES_VIEW = 'COMPLAINT_POST_BACKS_HISTORIES_VIEW',
    COMPLAINT_POST_BACKS_HISTORIES_RESEND = 'COMPLAINT_POST_BACKS_HISTORIES_RESEND',
}
