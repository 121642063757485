export async function smoothScrollToTheBottom(divRef: any): Promise<void> {
    if (!divRef.current) {
        return;
    }

    while ((divRef.current?.scrollHeight ?? 0) - (divRef.current?.clientHeight ?? 0) - divRef.current?.scrollTop >= 10) {
        if (!divRef.current) {
            break;
        }

        divRef.current.scrollTop += 10;
        await new Promise((resolve) => {
            setTimeout(resolve, 0);
        });
    }

    if (!divRef.current) {
        return;
    }

    divRef.current.scrollTop = (divRef.current?.scrollHeight ?? 0) - (divRef.current?.clientHeight ?? 0);
}

export async function scrollTo(divRef: any, scrollTop: number): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            if (!divRef.current) {
                return;
            }

            divRef.current.scrollTop = scrollTop - divRef.current.offsetTop;
            resolve();
        }, 300);
    });
}
