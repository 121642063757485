import {
    IUserMe,
} from './types';
import {
    IGetBySuperAdminQuery,
    IGetByUserQuery,
} from './types/requests';

import {
    PERMISSION,
    ROLE,
} from './constants';

export function getDefaultQueryUser(take: number): IGetByUserQuery {
    return {
        skip: 0,
        take,

        username: '',
    };
}

export function getDefaultQuerySuperAdmin(): IGetBySuperAdminQuery {
    return {
        skip: 0,
        take: 20,

        username: '',
    };
}

export function isAdmin(user: IUserMe | null): boolean {
    if (!user) {
        return false;
    }

    return user.role === ROLE.SUPER_ADMIN;
}

export function hasPermissionOrAdmin(user: IUserMe | null, permission: PERMISSION): boolean {
    if (!user) {
        return false;
    }
    if (isAdmin(user)) {
        return true;
    }

    return user.permissions.includes(permission);
}

export function hasPermissionsOrAdmin(user: IUserMe | null, permissions: PERMISSION[]): boolean {
    if (isAdmin(user)) {
        return true;
    }

    return permissions.some((permission) => hasPermissionOrAdmin(user, permission));
}
