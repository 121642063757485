import {
    IId,
    IItemWithId,
    IPaginatedQuery,
} from './types';

class Entity {
    public static findOneById<IItem extends IItemWithId>(id: IId | null | undefined, items: IItem[] | null | undefined): IItem | null {
        if (id === null) {
            return null;
        }
        if (!items) {
            return null;
        }

        return items.find((item) => item.id === id) ?? null;
    }

    public static paginateInfinity<IItem extends IItemWithId>(query: IPaginatedQuery, items: IItem[] | null, newItems: IItem[] | null | undefined): IItem[] | null {
        if (!newItems) {
            return items;
        }
        if (!query.skip) {
            return newItems;
        }

        return [...(items ?? []), ...newItems];
    }

    public static replace<IItem extends IItemWithId>(newItem: IItem | null | undefined, items: IItem[] | null): IItem[] | null {
        if (!items) {
            return items;
        }
        if (!newItem) {
            return items;
        }

        const index = items.findIndex((item) => item.id === newItem.id);

        if (index === -1) {
            return items;
        }

        const newItems = [...items];

        newItems[index] = newItem;

        return newItems;
    }
}

export default Entity;
