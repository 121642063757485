import {
    IProps,
} from './types';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    useAgentsByUser,
} from 'entities/agents/hooks';
import {
    getStartPage,
} from 'entities/auth/functions';
import {
    useCurrenciesByUser,
} from 'entities/currencies/hooks';
import {
    useLanguagesByUser,
} from 'entities/languages/hooks';
import {
    useMerchantsByUser,
} from 'entities/merchants/hooks';
import {
    usePaymentMethodsByUser,
} from 'entities/paymentMethods/hooks';
import {
    useUserPositionsByUser,
} from 'entities/userPositions/hooks';
import {
    useUserByMe,
} from 'entities/users/hooks';

import {
    PATH,
} from 'pages/constants';

import Loading from '../Loading';

import Header, {
    useHeaderRef,
} from './Header';

import styles from './Container.module.scss';

const Container: FC<IProps> = (props) => {
    const [isPending, setIsPending] = useState(true);

    const {getAuthUser} = useUserByMe();
    const {getCurrencies} = useCurrenciesByUser();
    const {getUserPositions} = useUserPositionsByUser();
    const {getLanguages} = useLanguagesByUser();
    const {getPaymentMethods} = usePaymentMethodsByUser();
    const {getMerchants} = useMerchantsByUser();
    const {getAgents} = useAgentsByUser();

    const location = useLocation();
    const navigate = useNavigate();

    const headerRef = useHeaderRef();

    useEffect(() => {
        (async () => {
            const res = await getAuthUser();

            if (res.user) {
                navigate(getStartPage(res.user, location.pathname));
            }
            if (!res.user && location.pathname === PATH.SIGN_IN) {
                setIsPending(false);

                return;
            }

            await Promise.all([
                getCurrencies(),
                getUserPositions(),
                getLanguages(),
                getPaymentMethods(),
                getMerchants(),
                getAgents(),
            ]);
            setIsPending(false);
        })();
    }, []);

    if (isPending) {
        return (
            <Loading isPage={true}/>
        );
    }

    return (
        <div className={styles.container}>
            {
                location.pathname !== PATH.SIGN_IN &&
                <Header
                    ref={headerRef}
                    className={styles.header}
                />
            }
            <div
                className={styles.content}
                style={{
                    minHeight: `calc(100vh - ${headerRef.current.getHeight() + 30}px)`,
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

export default Container;
