import {
    IPatchBySuperAdminBody,
    IPatchByUserBody,
    IPostBySuperAdminBody,
} from './types/requests';
import {
    IGetByUser,
    IGetOneByUser,
    IPatchBySuperAdmin,
    IPatchByUser,
    IPostBySuperAdmin,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    onUnauthorized,
} from 'entities/auth/functions';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';

export async function getByUser(): Promise<IGetByUser> {
    return new Fetch<IGetByUser>({
        url: `${config.URL}/${INSTANCE_PATH}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByUser(id: number): Promise<IGetOneByUser> {
    return new Fetch<IGetOneByUser>({
        url: `${config.URL}/${INSTANCE_PATH}/${id}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postBySuperAdmin(body: IPostBySuperAdminBody): Promise<IPostBySuperAdmin> {
    return new Fetch<IPostBySuperAdmin>({
        url: `${config.URL}/${INSTANCE_PATH}/super_admin`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {duplicatedNameError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function patchBySuperAdmin(body: IPatchBySuperAdminBody): Promise<IPatchBySuperAdmin> {
    return new Fetch<IPatchBySuperAdmin>({
        url: `${config.URL}/${INSTANCE_PATH}/super_admin`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {duplicatedNameError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function patchByUser(body: IPatchByUserBody): Promise<IPatchByUser> {
    return new Fetch<IPatchByUser>({
        url: `${config.URL}/${INSTANCE_PATH}`,
        method: 'PATCH',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {duplicatedNameError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}
