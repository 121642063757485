import {
    IPatchBySuperAdminBody,
    IPatchByUserBody,
    IPostBySuperAdminBody,
} from './types/requests';
import {
    IDeleteBySuperAdmin,
    IGetByUser,
    IGetOneByUser,
    IPatchBySuperAdmin,
    IPatchByUser,
    IPostBySuperAdmin,
    IPostRecoverBySuperAdmin,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    onUnauthorized,
} from 'entities/auth/functions';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';

export async function getByUser(): Promise<IGetByUser> {
    return new Fetch<IGetByUser>({
        method: 'GET',
        url: `${config.URL}/${INSTANCE_PATH}`,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function getOneByUser(id: string): Promise<IGetOneByUser> {
    return new Fetch<IGetOneByUser>({
        method: 'GET',
        url: `${config.URL}/${INSTANCE_PATH}/${id}`,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postBySuperAdmin(body: IPostBySuperAdminBody): Promise<IPostBySuperAdmin> {
    return new Fetch<IPostBySuperAdmin>({
        method: 'POST',
        url: `${config.URL}/${INSTANCE_PATH}`,
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_ID: {
                    return {duplicatedIdError: body.error};
                }
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {duplicatedNameError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function patchBySuperAdmin(body: IPatchBySuperAdminBody): Promise<IPatchBySuperAdmin> {
    return new Fetch<IPatchByUser>({
        method: 'PATCH',
        url: `${config.URL}/${INSTANCE_PATH}`,
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {duplicatedNameError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function patchByUser(body: IPatchByUserBody): Promise<IPatchByUser> {
    return new Fetch<IPatchByUser>({
        method: 'PATCH',
        url: `${config.URL}/${INSTANCE_PATH}/user`,
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.DUPLICATED_NAME: {
                    return {duplicatedNameError: body.error};
                }
                default: {
                    return body;
                }
            }
        })
        .exec();
}

export async function postRecoverBySuperAdmin(id: string): Promise<IPostRecoverBySuperAdmin> {
    return new Fetch<IPostRecoverBySuperAdmin>({
        method: 'POST',
        url: `${config.URL}/${INSTANCE_PATH}/recover/${id}`,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}

export async function deleteBySuperAdmin(id: string): Promise<IDeleteBySuperAdmin> {
    return new Fetch<IDeleteBySuperAdmin>({
        method: 'DELETE',
        url: `${config.URL}/${INSTANCE_PATH}/${id}`,
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 404, 500], (body) => {
            return body;
        })
        .exec();
}
