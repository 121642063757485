import {
    IElementInfo,
    IProps,
    IRef,
} from './types';

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';

import {
    scrollTo,
    smoothScrollToTheBottom,
} from './functions';

const InfiniteScroll = forwardRef<IRef, IProps>((props, ref) => {
    const [isCompletedInitialRequests, setIsCompletedInitialRequests] = useState(!props.withInitialRequests);

    const pendingTopRef = useRef(false);
    const pendingBottomRef = useRef(false);

    const divRef = useRef<HTMLDivElement>(null);

    const getElementInfo = (): IElementInfo => {
        return {
            clientHeight: divRef.current?.clientHeight ?? 0,
            scrollHeight: divRef.current?.scrollHeight ?? 0,
            scrollTop: props.withWindowScroll ?
                window.scrollY :
                divRef.current?.scrollTop ?? 0,
        };
    };
    const loadTop = async (isInitial = false) => {
        if (!divRef.current) {
            return;
        }
        if (pendingTopRef.current) {
            return;
        }
        if (!props.hasMoreTop && !isInitial) {
            return;
        }

        const scrollHeight = divRef.current.scrollHeight;

        pendingTopRef.current = true;
        await props.loadMoreTop?.();
        pendingTopRef.current = false;

        const newScrollHeight = divRef.current.scrollHeight;

        divRef.current.scrollTop = newScrollHeight - scrollHeight;

        if (isInitial) {
            setIsCompletedInitialRequests(true);
        }
    };
    const loadBottom = async (isInitial = false) => {
        if (pendingBottomRef.current) {
            return;
        }
        if (!props.hasMoreBottom && !isInitial) {
            return;
        }

        pendingBottomRef.current = true;
        await props.loadMoreBottom?.();
        pendingBottomRef.current = false;
    };
    const onScroll = async () => {
        if (!divRef.current) {
            return;
        }

        const {
            firstChild,
            lastChild,
            scrollTop,
            offsetTop,
            offsetHeight,
        } = divRef.current;

        if (!firstChild) {
            return;
        }
        if (!lastChild) {
            return;
        }

        let topEdge = 0;
        let bottomEdge = 0;
        let scrolledUp = 0;
        let scrolledDown = 0;

        if (props.withWindowScroll) {
            if (props.loadMoreTop) {
                throw new Error('NOT IMPLEMENTED');
            }

            const bodyRect = document.body.getBoundingClientRect();
            // @ts-ignore
            const lastChildRect = lastChild.getBoundingClientRect();

            // @ts-ignore
            topEdge = firstChild.offsetTop; // ??
            bottomEdge = lastChildRect.top - bodyRect.top;
            scrolledUp = scrollTop + offsetTop; // ??
            scrolledDown = offsetTop - bodyRect.top + window.innerHeight;
        } else {
            // @ts-ignore
            topEdge = firstChild.offsetTop;
            // @ts-ignore
            bottomEdge = lastChild.offsetTop + lastChild.offsetHeight;
            scrolledUp = scrollTop + offsetTop;
            scrolledDown = scrolledUp + offsetHeight;
        }
        if (topEdge >= scrolledUp) {
            await loadTop();
        }
        if (scrolledDown >= bottomEdge) {
            await loadBottom();
        }

        const elementInfo = getElementInfo();

        props.onScroll?.(elementInfo);
    };

    useImperativeHandle(ref, () => {
        return {
            async scrollToTheBottom(): Promise<void> {
                await smoothScrollToTheBottom(divRef);
            },
            async scrollTo(scrollTop: number): Promise<void> {
                await scrollTo(divRef, scrollTop);
            },
            async simulateScroll(): Promise<void> {
                await onScroll();
            },
            getElementInfo(): IElementInfo {
                return getElementInfo();
            },
        };
    });
    useEffect(() => {
        if (props.withInitialRequests) {
            (async () => {
                await loadTop(true);
            })();
            (async () => {
                await loadBottom(true);
            })();
        }
    }, []);
    useEffect(() => {
        if (props.withInitialRequests && !isCompletedInitialRequests) {
            return;
        }
        if (!divRef.current) {
            return;
        }
        if (pendingBottomRef.current) {
            return;
        }
        if (divRef.current.clientHeight !== divRef.current.scrollHeight) {
            return;
        }

        (async () => {
            await onScroll();
        })();
    }, [divRef.current, isCompletedInitialRequests, props.children, pendingBottomRef.current]);
    useEffect(() => {
        if (!props.withWindowScroll) {
            return;
        }

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    });

    return (
        <div
            ref={divRef}
            className={props.className}
            style={props.style}
            onScroll={onScroll}
        >
            {
                isCompletedInitialRequests && pendingTopRef.current &&
                props.loading
            }
            {props.children}
            {
                pendingBottomRef.current &&
                props.loading
            }
        </div>
    );
});

export default InfiniteScroll;
