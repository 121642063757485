import {
    useEffect,
} from 'react';

type ICallBack = (abortSignal: AbortSignal) => void | Promise<void>;

export default function(cb: ICallBack, delay: number, dependencies: any[]) {
    useEffect(() => {
        const abortController = new AbortController();
        const id = setTimeout(async () => {
            await cb(abortController.signal);
        }, delay);

        return () => {
            abortController.abort();
            clearTimeout(id);
        };
    }, dependencies);
}
