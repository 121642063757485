import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import RefreshImage from './RefreshImage';

import styles from './RefreshButton.module.scss';

const RefreshButton: FC<IProps> = (props) => {
    return (
        <button
            className={classNames(styles.refreshButton, props.className)}
            onClick={props.onClick}
        >
            <RefreshImage className={styles.refreshImage}/>
            <div>
                Refresh
            </div>
        </button>
    );
};

export default RefreshButton;
