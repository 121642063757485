import {
    useMemo,
} from 'react';

import {
    PERMISSION,
    ROLE,
} from 'entities/users/constants';
import {
    hasPermissionOrAdmin,
} from 'entities/users/functions';
import {
    useUserByMe,
} from 'entities/users/hooks';

interface IPermissionDescription<IActionPath> {
    permission: PERMISSION;
    actionPath: IActionPath;
}

interface ISettings<IActionPath> {
    actionPath: IActionPath;
    descriptions: IPermissionDescription<IActionPath>[];
}

export default function<IActionPath extends string>(settings: ISettings<IActionPath>) {
    const {authUser} = useUserByMe();

    const isAccessedEntity = useMemo(() => {
        if (authUser?.role === ROLE.SUPER_ADMIN) {
            return true;
        }

        return settings.descriptions.some((description) => {
            return (
                description.actionPath === settings.actionPath &&
                hasPermissionOrAdmin(authUser, description.permission)
            );
        });
    }, [authUser]);

    return {
        isAccessedEntity,
    };
}
