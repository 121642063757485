import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';
import {
    Link,
} from 'react-router-dom';

const OptionalLink: FC<IProps> = (props) => {
    if (props.isLink) {
        return (
            <Link
                className={props.className}
                to={props.to}
                target={'_blank'}
            >
                {props.children}
            </Link>
        );
    }

    return (
        <span className={props.className}>
            {props.children}
        </span>
    );
};

export default OptionalLink;
