import {
    useEffect,
    useState,
} from 'react';

export default function useBrowserVisibility(cb?: (isVisible: boolean) => Promise<void> | void, deps?: any[]) {
    const [isBrowserVisible, setIsBrowserVisible] = useState(document.visibilityState === 'visible');

    const onChangeVisibility = () => {
        const isBrowserVisible = document.visibilityState === 'visible';

        setIsBrowserVisible(isBrowserVisible);
        cb?.(isBrowserVisible);
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', onChangeVisibility);

        return () => {
            document.removeEventListener('visibilitychange', onChangeVisibility);
        };
    }, deps);

    return {
        isBrowserVisible,
    };
}
