import {
    IPostByUserBody,
    IPostCheckByUserBody,
} from './types/requests';
import {
    IGetBySuperAdmin,
    IPostByUser,
    IPostCheckByUser,
    IPostLogoutByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    INSTANCE_PATH,
    RESPONSE_ERROR,
} from './constants';
import {
    onUnauthorized,
} from './functions';

export async function getBySuperAdmin(userId: number): Promise<IGetBySuperAdmin> {
    return new Fetch<IPostCheckByUser>({
        url: `${config.URL}/${INSTANCE_PATH}/super_admin/${userId}`,
        method: 'GET',
    })
        .on([200], (body) => {
            return body;
        })
        .on([401], (body) => {
            onUnauthorized();

            return body;
        })
        .on([400, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postCheckByUser(body: IPostCheckByUserBody): Promise<IPostCheckByUser> {
    return new Fetch<IPostCheckByUser>({
        url: `${config.URL}/${INSTANCE_PATH}/check`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postByUser(body: IPostByUserBody): Promise<IPostByUser> {
    return new Fetch<IPostByUser>({
        url: `${config.URL}/${INSTANCE_PATH}`,
        method: 'POST',
        body,
    })
        .onEmptyBody([204])
        .on([401], (body) => {
            switch (body.error) {
                case RESPONSE_ERROR.INCORRECT_TOTP: {
                    return {
                        incorrectTOTPError: body.error,
                    };
                }
                case RESPONSE_ERROR.INCORRECT_IP: {
                    return {
                        incorrectIPError: body.error,
                    };
                }
                default: {
                    return {
                        unauthorizedError: body.error,
                    };
                }
            }
        })
        .on([400, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postLogoutByUser(): Promise<IPostLogoutByUser> {
    return new Fetch<IPostLogoutByUser>({
        url: `${config.URL}/${INSTANCE_PATH}/logout`,
        method: 'POST',
    })
        .onEmptyBody([204])
        .on([401, 500], (body) => {
            return body;
        })
        .exec();
}
