import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

const RefreshImage: FC<IProps> = (props) => {
    return (
        <svg
            className={props.className}
            width={'91'}
            height={'91'}
            viewBox={'0 0 91 91'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
        >
            <path
                d={'M45.5 7.58333C24.5594 7.58333 7.58333 24.5594 7.58333 45.5C7.58333 66.4406 24.5594 83.4167 45.5 83.4167C66.4406 83.4167 83.4167 66.4406 83.4167 45.5C83.4167 43.5971 83.2766 41.7275 83.0062 39.9C82.6659 37.6042 84.3464 35.3889 86.6669 35.3889C88.542 35.3889 90.1861 36.6841 90.4707 38.5375C90.819 40.8074 91 43.1325 91 45.5C91 70.6291 70.6291 91 45.5 91C20.3709 91 0 70.6291 0 45.5C0 20.3709 20.3709 0 45.5 0C57.1536 0 67.7834 4.38094 75.8333 11.5858V6.31944C75.8333 4.22538 77.531 2.52778 79.625 2.52778C81.719 2.52778 83.4167 4.22538 83.4167 6.31944V21.4861C83.4167 23.5801 81.719 25.2778 79.625 25.2778H64.4583C62.3643 25.2778 60.6667 23.5801 60.6667 21.4861C60.6667 19.3921 62.3643 17.6944 64.4583 17.6944H71.2788C64.5134 11.4195 55.4549 7.58333 45.5 7.58333Z'}
                fill={'#01080C'}
            />
        </svg>
    );
};

export default RefreshImage;
